<template>
  <div class="full-height">
    <a-card title="流程信息" class="full-height">
      <a-row gutter="16">
        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px">
          <a-input
            v-model="searchForm.search"
            placeholder="编号, 名称, 备注"
            allowClear
            @pressEnter="search"
          />
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-switch
            :checked="is_all"
            @change="() => handleSwitchChangeAll()"
            un-checked-children="一键完成"
          ></a-switch>
          <!-- <a-button type="primais_allry" icon="plus" style="margin: 0 8px;" @click="addWorkload">添加每日记录</a-button> -->
          <a-button
            type="primary"
            icon="plus"
            style="margin: 0 8px"
            @click="catWorkload"
            :disabled="!items.length"
            >查看工作记录</a-button
          >
          <!-- <a-button type="primary" icon="plus" style="margin: 0 8px;" :disabled="add_button"
            @click="addProcess(form)">新增流程</a-button> -->
          <a-button
            type="primary"
            icon="plus"
            style="margin: 0 8px"
            @click="ProcessManage"
            >流程管理</a-button
          >
        </div>
      </a-row>

      <a-row style="margin-bottom: 12px">
        <a-table
          :columns="columns"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
          <template slot="total_quantity" slot-scope="text, record">
            <editable-cell
              :text="text"
              @change="onCellChange(record, 'name', $event)"
            />
          </template>

          <template slot="is_completed" slot-scope="text, record">
            <!-- 注意这里的名称要与上面的scopedSlots定义一致 -->
            <a-switch
              size="small"
              :checked="
                record.total_quantity >= use_quantity
              "
              @change="(checked) => handleSwitchChange(record, checked)"
            ></a-switch>
          </template>
        </a-table>
      </a-row>
    </a-card>

    <!-- 这个是编辑流程 -->
    <form-modal
      v-model="visible"
      :form="targetItem"
      :processItems="processItems"
      @create="create"
      @update="update"
    />

    <!-- 工作记录 -->
    <workload
      ref="workload_ref"
      v-model="work_visible"
      :task_form="taskItem"
      :process_items="items"
      @create="create"
      @update="update"
    />
  </div>
</template>

<style scoped>
.full-height {
  min-height: 100vh;
  /* 确保组件至少有视口的完整高度 */
}
</style>

<script>
import { ProductProcessList, ProductProcessDestroy } from "@/api/production";
import { ProductionTaskInfo, TaskDone } from "@/api/production";
import { TaskProcessCreate } from "@/api/production";
import moment from "moment";
import NP from "number-precision";
export default {
  name: "Process",
  components: {
    FormModal: () => import("./FormModal.vue"),
    Workload: () => import("@/components/Workload/index"),
    EditableCell: () => import("@/components/EditableCell/EditableCell.vue"),
  },
  data() {
    return {
      columns: [
        {
          title: "步骤",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "步骤",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return `第${index + 1}步`;
          },
        },
        {
          title: "流程名称",
          dataIndex: "process_name",
          sorter: true,
        },
        {
          title: "未完成数量",
          dataIndex: "undone_quantity",
          customRender: (value, record) => {
            if (record.total_quantity) {
              var undone_quantity = this.use_quantity - record.total_quantity;
              // if (undone_quantity <= 0) {
              //   return '已完成'
              // }
              return undone_quantity;
            } else {
              return this.use_quantity;
            }
          },
        },
        {
          title: "已完成数量",
          dataIndex: "total_quantity",
          // width: '30px',
          scopedSlots: { customRender: "total_quantity" },
        },
        {
          title: "完成状态",
          dataIndex: "is_completed",
          key: "is_completed",
          scopedSlots: { customRender: "is_completed" }, // 使用作用域插槽来自定义渲染
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
      ],
      searchForm: { search: "", page: 1, page_size: 16 },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      visible: false,
      processItems: [], //流程列表
      processItemsFix: [], //固定的流程，第一次请求保持不变
      targetItem: {},
      taskItem: {},
      add_button: false,
      form: {},
      form_visible: false,
      work_visible: false,
    };
  },
  computed: {
    is_all() {
      const HaveDoneQuantity = this.items.reduce(
        (sum, item) => sum + item.total_quantity,
        0
      );
      if (this.taskItem.quantity == undefined) {
        return false;
      }

      const totalQuantity = NP.times(this.items.length, this.use_quantity);
      console.log("totalQuantity", totalQuantity);
      console.log("HaveDoneQuantity", HaveDoneQuantity);
      if (totalQuantity == HaveDoneQuantity) {
        return true;
      } else {
        return false;
      }
    },
    use_quantity() {
      if (this.taskItem.actual_quantity != null) {
        return this.taskItem.actual_quantity;
      } else {
        return this.taskItem.quantity;
      }
    },
  },
  methods: {
    check_is_all() {
      const HaveDoneQuantity = this.items.reduce(
        (sum, item) => sum + item.total_quantity,
        0
      );
      if (this.taskItem.quantity == undefined) {
        return false;
      }

      const totalQuantity = NP.times(this.items.length, this.use_quantity);
      console.log("totalQuantity", totalQuantity);
      console.log("HaveDoneQuantity", HaveDoneQuantity);
      if (totalQuantity == HaveDoneQuantity) {
        return true;
      } else {
        return false;
      }
    },

    handleSwitchChange(record, checked) {
      console.log('checked',checked)
      if (!checked) {
        this.$message.error("暂时无法修改");
        // this.onCellChange(record, '', 0)
      } else {
        this.onCellChange(record, "", this.use_quantity);
      }
    },

    handleSwitchChangeAll() {
      if (this.check_is_all()) {
        this.$message.error("无法回调,请在查看工作记录中进行更改");
      } else {
        this.onCellChange(null, "", this.use_quantity);
      }
    },
    getProductProcessList(goods) {
      if (goods === null) {
        this.$message.error("无法回调,请在查看工作记录中进行更改");
        this.loading = false;
        this.add_button = true;
        return "";
      }
      ProductProcessList({ goods: goods })
        .then((data) => {
          // 根据任务中产品的ID获取这个成品的生产流程
          this.pagination.total = data.count;
          this.items = data.results;
          this.items = this.items.map(
            (item) => ({
              ...item,
              number: this.taskItem.number,
              process_name: item.process_detail.name,
              goods_name: this.taskItem.goods_name,
            }),
            TaskDone({ task_id: this.taskItem.id }).then((data) => {
              console.log(data);
              this.mergeData(data["task"]);
            })
          );
        })
        .finally(() => {
          this.loading = false;
          this.fitterPress();
        });
    },
    getProductionTaskInfo() {
      this.loading = true;
      // 获得某个任务信息
      ProductionTaskInfo({ id: this.$route.query.search }).then((data) => {
        console.log(data);
        this.taskItem = data;
        // 通过任务中的good获取流程信息
        this.getProductProcessList(data.goods);
      });
    },

    fitterPress() {
      this.processItems = this.processItemsFix.filter(
        (p) => !this.items.some((up) => up.process === p.id)
      );
      if (this.processItems.length == 0) {
        this.add_button = true;
      } else {
        this.add_button = false;
      }
    },

    mergeData(processStats) {
      const processMap = processStats.reduce((map, item) => {
        map[item.process] = item;
        return map;
      }, {});
      // console.log(this.taskItem.quantity,)
      // console.log(record.total_quantity >= this.taskItem.quantity,)
      this.items = this.items.map((record) => ({
        ...record,
        // is_completed: record.total_quantity >= this.taskItem.quantity,
        ...processMap[record.process],
      }));
      this.items.sort((a, b) => a.id - b.id);
      // 适当的后续处理
    },
    //添加工作量
    // addWorkload() {
    //   console.log('添加记录',)
    //   this.form_visible = true;
    // },

    //查看工作量
    catWorkload() {
      this.$refs.workload_ref.search();
      this.work_visible = true;
    },

    isInteger(value) {
      return /^\d+$/.test(value);
    },

    onCellChange(record, dataIndex, value) {
      if (record != null) {
        record.total_quantity = value;
      } else {
        this.items.forEach((process) => {
          process.total_quantity = this.use_quantity;
        });
      }
      var is_all = this.check_is_all();
      console.log(is_all);
      console.log(record);
      if (record) {
        if (!this.isInteger(value)) {
          this.$message.error("请输入正整数");
          return;
        }

        var formatData = {
          production_task: this.taskItem.id,
          process: record.process,
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
          worker_name: "默认工人",
          quantity_all: value,
          is_all: is_all,
        };
      } else {
        //这种就是总开关
        var formatData = {
          production_task: this.taskItem.id,
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
          worker_name: "默认工人",
          quantity_all: value,
          is_all: is_all,
        };
      }
      console.log(formatData);

      this.loading = true;

      //表格编辑都是创建新的
      TaskProcessCreate(formatData)
        .then((data) => {
          this.$message.success("新增成功");
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response && error.response.status === 400) {
            this.$message.error(`${error.response.data.info}`); // 可以根据后端返回的具体错误信息进行更详细的提示
          } else {
            // 处理其他类型的错误
            this.$message.error("请求失败，请稍后重试");
          }
        })
        .finally(() => {
          this.loading = false;
          this.list();
        });
    },

    initialize() {
      this.getProductionTaskInfo();

      // this.list();
    },
    list() {
      this.loading = true;
      this.getProductProcessList(this.taskItem.goods);
    },
    create(item) {
      // this.items.splice(0, 0, item);
      this.list();
    },
    update(item) {
      this.items.splice(
        this.items.findIndex((i) => i.id == item.id),
        1,
        item
      );
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    addProcess(item) {
      this.visible = true;
      if (item.process) {
        this.targetItem = {
          goods: item.goods,
          process: item.process,
          name: item.process_name,
          id: id,
        };
      } else {
        this.targetItem = { goods: this.taskItem.goods };
      }
    },

    ProcessManage() {
      this.$router.push({
        // path: "/purchasing/purchase_create",
        path: "/production/production_task_manage",
        // name:"production_task_manage",
        query: {
          //只能传递简单的键值对
          goods: this.taskItem.goods,
        },
      });
    },
    destroy(id) {
      ProductProcessDestroy({ id }).then(() => {
        // this.items.splice(this.items.findIndex(item => item.id == id), 1);
        this.$message.success("删除成功");
        this.list();
      });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${
        sorter.field
      }`;
      this.list();
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>